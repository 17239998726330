
#contactDiv h1{
    color: white;
    padding-left: 10px;
}

#contactDiv h2{
    color: white;
    padding-left: 10px;
}

#contactDiv p{
    color: white;
    padding-left: 10px;
}

#contactDiv [id^=largerText]{
    font-size: large;
}

#contactDiv li p{
    margin: 0;
}

#contactDiv li{
    color: white;
}

#contactDiv a{
    color: lightblue;
}

#contactDiv a:hover{
    color: rgb(213, 245, 255);
}