#cancomputediv{
    margin-left: 10px;
    color: white;
}

.leftrightdiv{
    display: flex;
    justify-content: space-between;
}

.ColourRed{
    color: red;
    font-weight: bold;
}

#cancomputediv .returntorpojects{
    font-weight: bold;
    margin-right: 10px;
    color: white;
}

#cancomputediv a{
    color: white;
    text-decoration: none;
    color: lightblue;
}


.list-group-item{
    background-color: rgb(45, 45, 45);
    color: white;
    margin-right: 10px;
    border-color: rgb(20, 20, 20);
}

#cancomputediv a:hover{
    text-decoration: underline;
}