#minecleanerdiv{
    margin-left: 10px;
    color: white;
}

.leftrightdiv{
    display: flex;
    justify-content: space-between;
}

.carousel{
    width: 30rem;
}

.carousel h5{
    color: white;
}

.carousel p{
    color: white;
}

.ColourRed{
    color: red;
    font-weight: bold;
}

.accordion-button{
    color: white;
    background-color: rgb(45, 45, 45);
}

.accordion-button:focus{
    color: white;
    background-color: rgb(45, 45, 45);
    
}

.accordion-item{
    background-color: rgb(20, 20, 20);
    color: white;
    margin-right: 10px;
}

#minecleanerdiv .returntorpojects{
    font-weight: bold;
    margin-right: 10px;
    color: white;
}

#minecleanerdiv a{
    color: white;
    text-decoration: none;
    color: lightblue;
}

.accordion-button.collapsed::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.list-group-item{
    background-color: rgb(45, 45, 45);
    color: white;
    margin-right: 10px;
    border-color: rgb(20, 20, 20);
}

#minecleanerdiv a:hover{
    text-decoration: underline;
}