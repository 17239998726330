

#cardsDiv .card{
    background-color: rgb(45, 45, 45);
    color: white;
    margin: 5px;
}

#cardsDiv2 .card{
    background-color: rgb(45, 45, 45);
    color: white;
    margin: 5px;
}


#projectsDiv{
    margin-left: 10px;
    color: white;
}

#badge2ha{
    margin-left: 5px;
}