
body{
    background-color: rgb(25,25,25);
}

.closeButton {
    color: #ffffff;
}

#aboutDiv h4{
    padding-left: 10px;
}

a{
    color: lightblue;
}

.nav-link{
    color: lightblue;
}

#oldLink{
    color: lightblue;
}

#oldLink:hover{
    color: rgb(213, 245, 255);
}

[id^=moreInfo]{
    padding: 0;
    padding-left: 2px;
    padding-right: 2px;
    font-size: small;
    font-weight: bold;
}

#allOtherButton{
    margin-top: 4px;
    padding: 4px;
    font-weight: bold;
    font-size: small;
}

.offcanvas-body{
    background-color: rgb(25,25,25);
    color: white;
}

.offcanvas-header{
    background-color: rgb(45, 45, 45);
    color: white;
}

#aboutDiv{
    color: white;
}

#aboutDiv h1{
    padding-left: 10px;
}

#aboutDiv p{
    padding-left: 10px;
}
