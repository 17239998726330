

.color-nav {
    background-color: rgb(45, 45, 45);
}

.navbar > .container {
    max-width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    padding: 0;
    font-size: 16px;
  }
  
  .navbar {
    margin: 0;
    padding: 0;
  }

#topNavBar .Nav_link{
    padding-right: 15px;
    text-decoration: none;
    font-weight: bold;
    color: white;
}

#topNavBar .Nav_link:hover{
    text-decoration: underline;
}